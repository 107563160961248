.main{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
  }
  .profile-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    width: 100%;
    border-radius: 25px;
    padding: 30px;
    border: 1px solid #ffffff40;
    box-shadow: 0 5px 20px rgba(0,0,0,0.4);
  }
  .image{
    position: relative;
    height: 150px;
    width: 150px;
  }
  .image .profile-pic{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 5px 20px rgba(0,0,0,0.4);
  }
  .data{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }
  .data h2{
    font-size: 33px;
    font-weight: 600;
  }
 .profile-span{
    font-size: 18px;
    margin-top: 10px;
  }
  .row{
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  .row .info{
    text-align: center;
    padding: 0 20px;
  }
  .buttons{
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  .buttons .btn{
    color: #fff;
    text-decoration: none;
    margin: 0 20px;
    padding: 8px 25px;
    border-radius: 25px;
    font-size: 18px;
    white-space: nowrap;
    background: #007bff;
  }
  .buttons .btn:hover{
    box-shadow: inset 0 5px 20px rgba(0,0,0,0.4);
  }

  .back-button{
    margin-bottom: 25px;
    position: relative;
    left: 50%;
  }